import React from "react";
import Gallery from "react-grid-gallery";
import Layout from "../components/layout";
import { images } from "../data/images";

const PageGallery = () => (
  <Layout>
    <h1>Gallery</h1>
    <div className="body body--gallery">
      <div>
        <Gallery images={images}/>
      </div>
    </div>
  </Layout>
);

export default PageGallery;
