export const images = [
  {
    src: "https://efgl-gallery.videogamers.eu/82431203_1609654175858714_5934641838014070784_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/82431203_1609654175858714_5934641838014070784_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/82693292_456792535207845_8935165162764107776_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/82693292_456792535207845_8935165162764107776_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/83062919_461295318083954_5681874701882753024_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/83062919_461295318083954_5681874701882753024_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/67776438_2337939893135413_5539028968700641280_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/67776438_2337939893135413_5539028968700641280_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/67788794_464057554374264_9185339693426802688_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/67788794_464057554374264_9185339693426802688_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/68363492_402113833771363_436513971722256384_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/68363492_402113833771363_436513971722256384_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/68713343_466800014102419_7271557392234446848_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/68713343_466800014102419_7271557392234446848_n.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/52797067_312137662774457_5307615873094123520_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/52797067_312137662774457_5307615873094123520_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/53080103_1124882277681411_8694039080222064640_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/53080103_1124882277681411_8694039080222064640_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55823788_828736510793173_1842912957101506560_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55823788_828736510793173_1842912957101506560_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55829683_619251445184113_5575357369753272320_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55829683_619251445184113_5575357369753272320_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55831600_605275436612805_4511356312537268224_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55831600_605275436612805_4511356312537268224_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55832973_320757791962991_2576360214387228672_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55832973_320757791962991_2576360214387228672_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55853484_266759694199436_4022504659619938304_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55853484_266759694199436_4022504659619938304_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55853655_266807744204538_7438258580664352768_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55853655_266807744204538_7438258580664352768_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55869191_983200198543185_4297799848406548480_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55869191_983200198543185_4297799848406548480_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/55925686_2213866095335555_9116780247172251648_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/55925686_2213866095335555_9116780247172251648_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56157882_407592009803604_3846063602054201344_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56157882_407592009803604_3846063602054201344_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56164199_340961180112081_4875101100039995392_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56164199_340961180112081_4875101100039995392_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56189949_268007950807141_2096683002456178688_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56189949_268007950807141_2096683002456178688_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56196637_280960766155217_6305311955338919936_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56196637_280960766155217_6305311955338919936_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56205052_2571430456263536_1774915163720253440_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56205052_2571430456263536_1774915163720253440_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56226089_2318920258321001_192290354471370752_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56226089_2318920258321001_192290354471370752_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56236119_540502706356897_1240011467000905728_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56236119_540502706356897_1240011467000905728_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56314654_2221687281480917_7674471439663104000_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56314654_2221687281480917_7674471439663104000_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56314672_406888866772092_8356446331404812288_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56314672_406888866772092_8356446331404812288_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56325215_558066561348605_6429271892686798848_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56325215_558066561348605_6429271892686798848_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56350778_371065886948008_8049490344519139328_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56350778_371065886948008_8049490344519139328_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56367322_337936740185556_3444220015094530048_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56367322_337936740185556_3444220015094530048_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56456168_435025217244188_5289910093754662912_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56456168_435025217244188_5289910093754662912_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56575967_2129532537161985_7038351306695639040_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56575967_2129532537161985_7038351306695639040_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56641424_273663330178011_8257685671557201920_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56641424_273663330178011_8257685671557201920_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  },
  {
    src: "https://efgl-gallery.videogamers.eu/56656214_2258925810991376_963325726717640704_n.jpg",
    thumbnail: "https://efgl-gallery.videogamers.eu/56656214_2258925810991376_963325726717640704_n_tn.jpg",
    thumbnailWidth: 215,
    thumbnailHeight: 180
  }
].splice(0, 33);

export default {
  images,
};